<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">Ubah Data Diri</h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Ubah data diri anda</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="submit"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          Save Changes
        </button>
      </div>
    </div>
    <validation-observer ref="formProfile">
      <b-form>
        <!--end::Header-->
        <!--begin::Form-->
        <div class="card-body">
          <!--begin::Heading-->
          <div class="row">
            <label class="col-xl-3"></label>
            <div class="col-lg-9 col-xl-6">
              <h5 class="font-weight-bold mb-6">Account:</h5>
            </div>
          </div>
          <!--begin::Form Group-->
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label">Username</label>
            <div class="col-lg-9 col-xl-6">
              <div>
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  rules="required"
                >
                  <input
                    class="form-control form-control-lg form-control-solid"
                    type="text"
                    ref="username"
                    v-model="currentUserForm.body.username"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <span class="form-text text-muted"
                >Username will not be publicly displayed.
                <a href="#" class="kt-link">Learn more</a>.</span
              >
            </div>
          </div>

          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label">Name</label>
            <div class="col-lg-9 col-xl-6">
              <validation-provider
                #default="{ errors }"
                name="Nama"
                rules="required"
              >
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  id="input-1"
                  v-model="currentUserForm.body.name"
                  type="text"
                  placeholder="Masukkan Nama"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label">Alamat</label>
            <div class="col-lg-9 col-xl-6">
              <validation-provider
                #default="{ errors }"
                name="Alamat"
                rules="required"
              >
                <b-form-textarea
                  :state="errors.length > 0 ? false : null"
                  id="input-1"
                  v-model="currentUserForm.body.address"
                  type="text"
                  ref="alamat"
                  rows="3"
                  placeholder="Masukkan Alamat"
                ></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
          </div>

          <div class="separator separator-dashed my-5"></div>
          <!--begin::Form Group-->
          <!-- <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-6">
            <h5 class="font-weight-bold mb-6">Security:</h5>
          </div>
        </div> -->
          <!--begin::Form Group-->
          <!-- <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label"
            >Login verification</label
          >
          <div class="col-lg-9 col-xl-6">
            <button
              type="button"
              class="btn btn-light-primary font-weight-bold btn-sm"
            >
              Setup login verification
            </button>
            <p class="form-text text-muted pt-2">
              After you log in, you will be asked for additional information to
              confirm your identity and protect your account from being
              compromised. <a href="#" class="font-weight-bold">Learn more</a>.
            </p>
          </div>
        </div> -->
          <!--begin::Form Group-->
          <!-- <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label"
            >Password reset verification</label
          >
          <div class="col-lg-9 col-xl-6">
            <div class="checkbox-single">
              <label class="checkbox m-0">
                <input
                  type="checkbox"
                  ref="verification"
                  :checked="authUser.verification"
                /><span></span> Require personal information to reset your
                password.</label
              >
            </div>
            <p class="form-text text-muted py-2">
              For extra security, this requires you to confirm your email or
              phone number when you reset your password.
              <a href="#" class="font-weight-boldk">Learn more</a>.
            </p>
            <button
              type="button"
              class="btn btn-light-danger font-weight-bold btn-sm"
            >
              Deactivate your account ?
            </button>
          </div>
        </div> -->
        </div>
      </b-form>
    </validation-observer>
    <!--end::Form-->
  </div>
  <!--end::Card-->
</template>

<script>
import { mapGetters } from "vuex";
import { UPDATE_ACCOUNT_INFO } from "@/core/services/store/profile.module";
import { UPDATE_PROFILE } from "@/core/services/store/auth.module";
import mixin from "@/core/services/Helpers";

import Swal from "sweetalert2";

export default {
  name: "AccountInformation",
  mixins: [mixin],
  data() {
    return {
      currentUserForm: {
        body: {},
      },
    };
  },
  methods: {
    save() {
      this.$refs.formProfile.validate().then((success) => {
        console.log(success);
        if (success) {
          this.isLoading = true;
          const _data = JSON.parse(JSON.stringify(this.currentUserForm));
          this.$store
            .dispatch(UPDATE_PROFILE, _data)
            .then((response) => {
              Swal.fire({
                title: response.message,
                text: `Berhasil mengubah Profile`,
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: false,
              });

              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
              this.$bvToast.toast(this.errors, {
                title: `Gagal Mengubah Profile`,
                variant: "danger",
                solid: true,
              });
            });
        }
      });
    },
    onreset() {},
    cancel() {
      this.$refs.username.value = this.authUser.username;
      this.$refs.email.value = this.authUser.email;
      this.$refs.language.value = this.authUser.language;
      this.$refs.time_zone.value = this.authUser.time_zone;
      this.$refs.email_com.checked = this.authUser.communication.email;
      this.$refs.sms_com.checked = this.authUser.communication.sms;
      this.$refs.phone_com.checked = this.authUser.communication.phone;
      this.$refs.verification.checked = this.authUser.verification;
    },
  },
  mounted() {
    const user = this.$store.getters.currentUser;

    const detail = this.detailUser(user);

    this.currentUserForm.body = {
      username: user.username,
      address: detail.address,
      name: user.name,
    };
  },
  computed: {},
};
</script>
